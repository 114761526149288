export function _resolvePatientJourneyListColumnOfType_hopcoClinicalCheck(column, patientJourney, row) {
    const property = column.property || 'hopcoClinicalCheckValue'

    if (row[property]) {
        column.setExplicitValueConfig(row[property])
    }
}

export function _resolvePatientJourneyListColumnOfType_hopcoMultiSurveyRag(column, patientJourney, row) {
    if (row[column.id]) {
        column.setExplicitValueConfig(row[column.id].value)
    }
}
