import _ from 'lodash'
import ActivityListMilestone from '@model/ActivityListMilestone'
import ActivityListService from '@serv/ActivityListService'
import AppointmentMilestone from '@model/AppointmentMilestone'
import CarePeriodMilestone from '@model/CarePeriodMilestone'
import Config from '@serv/Config'
import DataService from '@serv/DataService'
import GoalMilestone from '@model/GoalMilestone'
import ListService from '@serv/ListService'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import Milestone from '@model/Milestone'
import MilestoneService from '@serv/MilestoneService'
import moment from 'moment'
import NotifyService from '@serv/NotifyService'
import Patient from '@model/Patient'
import PatientJourney from '@model/PatientJourney'
import PeriodMilestone from '@model/PeriodMilestone'
import ReferralMilestone from '@model/ReferralMilestone'
import Request from '@serv/Request'
import RtmPeriodReviewMilestone from '@model/RtmPeriodReviewMilestone'
import Schedule from '@model/Schedule'
import ScheduledReviewMilestone from '@model/ScheduledReviewMilestone'
import store from '@src/store/index'
import StringHelper from '@serv/StringHelper'
import SurveyReviewMilestone from '@model/SurveyReviewMilestone'
import TaskReviewMilestone from '@model/TaskReviewMilestone'
import { useFeatureFlags } from '@composables/state/useFeatureFlags'
import User from '@model/User'
import Utils from '@serv/Utils'

const contentTypeToClass = {}

/**
 * Functions for managing the patient profile, e.g. helpers for Patient Page components.
 * Keep things functional to allow testing without state.
 */
class PatientService {
    constructor() {
        contentTypeToClass[Milestone.Type.appointment] = AppointmentMilestone
        contentTypeToClass[Milestone.Type.carePeriod] = CarePeriodMilestone
        contentTypeToClass[Milestone.Type.default] = Milestone
        contentTypeToClass[Milestone.Type.default] = Milestone
        contentTypeToClass[Milestone.Type.goal] = GoalMilestone
        contentTypeToClass[Milestone.Type.referral] = ReferralMilestone
        contentTypeToClass[Milestone.Type.surveyReview] = SurveyReviewMilestone
        contentTypeToClass[Milestone.Type.scheduledReview] = ScheduledReviewMilestone
        contentTypeToClass[Milestone.Type.period] = PeriodMilestone
        contentTypeToClass[Milestone.Type.rtmPeriodReview] = RtmPeriodReviewMilestone
        contentTypeToClass[Milestone.Type.taskReview] = TaskReviewMilestone
        contentTypeToClass[Milestone.Type.activityList] = ActivityListMilestone
    }

    // Create any Milestone object from JSON.
    constructObjectFromJson(json) {
        const Class = contentTypeToClass[json.type]
        if (Class == undefined) {
            Logging.warn(`Unrecognised Milestone type: ${json.type}`)
        } else {
            return new Class(json)
        }
    }

    // Parse a single Milestone from JSON
    parseMilestone(json) {
        json.type = json.type || Milestone.Type.default
        const milestone = this.constructObjectFromJson(json)

        if (!milestone) {
            Logging.warn(`Unrecognised Milestone type: ${json.type}`)
        }

        if (milestone && milestone.milestoneId && store.state.resources.milestones) {
            milestone.slug = (store.state.resources.milestones[milestone.milestoneId] || {}).slug
        }

        return milestone
    }

    // Parse a JSON array, and return created Milestone objects as an array.
    parseMilestonesArray(objectArray) {
        // Parse
        const milestones = []
        objectArray.forEach(json => {
            const milestone = this.parseMilestone(json)
            if (milestone) {
                milestones.push(milestone)
            }
        })

        return milestones
    }

    // Get a Journey object from its id (not slug)
    getJourneyById(journeyId, journeys) {
        return Utils.findSingleObjectWithKeyValue(journeys, 'id', journeyId, 'Journey')
    }

    // Get a Provider object from its id (not slug)
    getProviderById(providerId, providers) {
        return Utils.findSingleObjectWithKeyValue(providers, 'id', providerId, 'Provider')
    }

    // Get the set of provider objects from an array of leads (and map of all providers)
    getProvidersFromLeads(leads, allProviders) {
        const providerSlugs = new Set()
        leads.forEach(lead => {
            lead.providerSlugs.forEach(slug => providerSlugs.add(slug))
        })
        let providers = [...providerSlugs].map(slug => allProviders[slug])
        providers.sort((a, b) => a.title.localeCompare(b.title))

        return providers
    }

    /**
     * Get a list of Journey objects for a lead, sorted alphabetically.
     * Unless the 3rd param is passed as true, legacy journeys will be removed.
     */
    getLeadSortedJourneys(lead, allJourneys, includeLegacy) {
        const journeys = Object.values(allJourneys).filter(journey => lead.journeySlugs.includes(journey.slug))
        journeys.sort((a, b) => a.title.localeCompare(b.title))

        return includeLegacy ? journeys : journeys.filter(journey => !journey.isLegacy)
    }

    // From a lead and provider, get the array of matching team departments.
    getLeadTeamProviderDepartments(lead, provider) {
        const departmentSlugs = lead.departmentSlugs.filter(slug => provider.departmentSlugs.includes(slug))

        return departmentSlugs.map(slug => store.state.resources.departments[slug])
    }

    /**
     * Get a list of Journey objects, sorted alphabetically.
     * Unless the 2nd param is passed as true, legacy journeys will be removed.
     */
    getSortedJourneys(journeys, includeLegacy) {
        journeys.sort((a, b) => a.title.localeCompare(b.title))

        return includeLegacy ? journeys : journeys.filter(journey => !journey.isLegacy)
    }

    // Get available procedure labels for the specified journey objects
    // Each label is returned as object with slug and titleLocalised fields
    getProcedureLabelsForJourneys(journeys) {
        let procedureLabels = new Set()
        journeys.forEach(journey => {
            procedureLabels = new Set([...procedureLabels, ...(journey.procedureLabels || [])])
        })
        procedureLabels = [...procedureLabels].map(procedureLabel => ({
            slug: procedureLabel,
            titleLocalised: Locale.getLanguageItemForModelEnum(
                'procedureLabel',
                StringHelper.slugToCamelCase(procedureLabel)
            )
        }))
        procedureLabels.sort((a, b) => a.titleLocalised.localeCompare(b.titleLocalised))

        return procedureLabels
    }

    // Get the PatientPage config for a specified Journey and Owner.
    getPatientPageTabsForJourneyAndOwner(journey, owner, user) {
        let patientPageTabs = journey.patientPageTabs
        if (patientPageTabs == undefined) {
            const ownerPatientPageTabs = owner.keyValues?.dash?.patientPageTabs || []
            const ownerReplaceTabs = ['goals', 'rehab'] // If these tabs are found on both objects, we'll ignore the journey tab entirely
            const journeyPatientPageTabs =
                DataService.filterConfigObjectWithRole(
                    { patientPageTabs: journey.keyValues.patientPageTabs },
                    user.role
                )?.patientPageTabs || []
            const journeyReplaceTabs = [] // If these tabs are found on both objects, we'll ignore the owner tab entirely

            // Entirely replace tabs where we have certain duplication
            let allTabs = []
            const tabLists = [ownerPatientPageTabs, journeyPatientPageTabs]
            const replaceLists = [ownerReplaceTabs, journeyReplaceTabs]
            for (const index of [0, 1]) {
                for (const tabConfig of tabLists[index]) {
                    const tabName = tabConfig.tab
                    if (replaceLists[1 - index].includes(tabName)) {
                        const matchingConfig = tabLists[1 - index].find(config => config.tab == tabName)
                        if (matchingConfig) {
                            continue
                        }
                    }
                    allTabs.push(tabConfig)
                }
            }
            // Get a union of components that sit under the same tabs
            const groupedTabs = _.groupBy(allTabs, 'tab')
            patientPageTabs = []
            Object.keys(groupedTabs).forEach(tabName => {
                const tabArray = groupedTabs[tabName]
                let componentsUnion = []
                let isDefault

                tabArray.forEach(tabConfig => {
                    componentsUnion = _.union(componentsUnion, tabConfig.components)
                    isDefault = tabConfig.isDefault
                })

                patientPageTabs.push({
                    tab: tabName,
                    isDefault: isDefault,
                    components: componentsUnion
                })
            })
            journey.patientPageTabs = patientPageTabs
        }
        // Logging.log('patientPageTabs: ' + JSON.stringify(patientPageTabs))
        if (user != undefined && user.has(User.Capability.canViewPatientData)) {
            return patientPageTabs
        }

        return patientPageTabs.filter(pageTab => {
            return ['admin', 'procedure'].includes(pageTab['tab'])
        })
    }

    // Get the PatientInvitePage config for a specified Owner.
    // Returns a single array of TabCpt configs.
    getPatientInvitePageCptsForOwner(owner) {
        // eslint-disable-next-line no-constant-binary-expression
        let patientPageTabs = [...((owner.keyValues || {}).dash || {}).patientPageTabs] || []
        // Alphabetical sort on tab name ensure 'admin' processed before 'procedure'
        patientPageTabs.sort((a, b) => a.tab.localeCompare(b.tab))
        let inviteCpts = []
        patientPageTabs.forEach(tabConfig => {
            if (tabConfig.tab == 'admin' || tabConfig.tab == 'procedure') {
                const tabInviteCpts = tabConfig.components.filter(cptConfig => cptConfig.invite == true)
                inviteCpts = [...inviteCpts, ...tabInviteCpts]
            }
        })

        return inviteCpts
    }

    // Get the title for a patient header.
    getPatientPageTitle(patient) {
        if (!patient) {
            Logging.warn('getPatientPageTitle called with undefined patient')

            return ''
        }

        return patient.fullName
    }

    /**
     * Get the schedule start and end moments for the patient (as an array [start, end]).
     * NOTE: The 'patient' input can be a Moment object. In this case, we assume all scheduleSlugs are relative to this moment.
     * If pathwayJourney is specified, we search for PJMs only on this PJ.
     */
    getPatientScheduleStartEndMoments(patient, scheduleSlug, pathwayJourney) {
        const schedule = Schedule.get(scheduleSlug)
        if (schedule == undefined) {
            return [undefined, undefined]
        }
        let patientJourney
        if (MilestoneService.isMilestoneSlugGlobal(schedule.milestone)) {
            patientJourney = patient.globalJourney
        } else {
            patientJourney = pathwayJourney || patient.firstJourney
        }
        let milestoneMoment
        if (patient instanceof moment) {
            // We are specifying the schedule milestone date directly
            milestoneMoment = patient
        } else {
            // Special case: if 'registration' but no date, use 'invitation'
            let milestoneSlug = schedule.milestone
            if (milestoneSlug == 'registration' && !patientJourney.getMilestoneOfSlugDate('registration')) {
                milestoneSlug = 'invitation'
            }
            const milestone = patientJourney.getMilestoneOfSlug(
                milestoneSlug,
                schedule.qualifier,
                false, // includeInactive
                schedule.milestoneSubtype
            )
            if (milestone == undefined || milestone.moment == undefined) {
                return [undefined, undefined]
            }
            milestoneMoment = milestone.moment
        }

        let startOffset = schedule.startOffset
        let endOffset = schedule.endOffset

        // If schedule ending on op day, extend by 1 day.
        // This allows results on op day to qualify for "pre-op" schedule window, and is the same custom rule applied in
        // the app scheduler.
        if (schedule.milestone == 'operation' && endOffset == 0) {
            endOffset += 1
        }

        const startMoment = milestoneMoment.clone().add(startOffset, 'days')
        const endMoment = milestoneMoment.clone().add(endOffset, 'days')

        return [startMoment, endMoment]
    }

    /**
     * Is the Schedule from the specified slug active for the patient?
     * NOTES:
     * - inMoment can be any datetime, so we use moment.startOf('day') to round to a date.
     * - the 'patient' input can be a Moment object. In this case, we assume all scheduleSlugs are relative to this moment.
     * If pathwayJourney is specified, we search for PJMs only on this PJ.
     */
    isPatientScheduleActiveAtMoment(patient, scheduleSlug, inMoment, pathwayJourney) {
        if (scheduleSlug.includes('always')) {
            return true
        }
        const schedule = Schedule.get(scheduleSlug)
        if (schedule.filterMilestoneSlug) {
            let filterJourney
            if (MilestoneService.isMilestoneSlugGlobal(schedule.filterMilestoneSlug)) {
                filterJourney = patient.globalJourney
            } else {
                filterJourney = pathwayJourney || patient.firstJourney
            }
            if (!_.isEmpty(filterJourney.getMilestonesOfSlug(schedule.filterMilestoneSlug))) {
                // Schedule specifies to filter on milestone, and milestone exists
                return false
            }
        }
        const [startMoment, endMoment] = this.getPatientScheduleStartEndMoments(patient, scheduleSlug, pathwayJourney)
        if (startMoment && endMoment) {
            const inDate = inMoment.clone().startOf('day')
            if (inDate.isSameOrAfter(startMoment) && inDate.isBefore(endMoment)) {
                // moment is within schedule limits
                const schedule = Schedule.get(scheduleSlug)
                if (!schedule.isRepeating) {
                    return true
                }
                // Check if moment is within repeating window
                const startDaysOfs = inDate.diff(startMoment, 'days')
                if (startDaysOfs % schedule.interval < schedule.intervalEndOffset) {
                    return true
                }
            }
        }

        return false
    }

    /**
     * Return true if the schedule is active for the patient.
     * Consider ALL patient milestones that match the schedule slug.
     * If pathwayJourney is specified, we search for PJMs only on this PJ.
     */
    isPatientScheduleActiveNow(patient, scheduleSlug, pathwayJourney) {
        return this.isPatientScheduleActiveAtMoment(patient, scheduleSlug, moment(), pathwayJourney)
    }

    /**
     * From a list of scheduleSlugs, find the one representing the schedule whose end is closest
     * and BEFORE the patient milestone. If none found, return undefined.
     * If pathwayJourney is specified, we search for PJMs only on this PJ.
     */
    getPatientScheduleSlugClosestEndBeforeMoment(patient, scheduleSlugs, inMoment, pathwayJourney) {
        let minDaysBefore = 999
        let minScheduleSlug = undefined
        const inDate = inMoment.clone().startOf('day')
        scheduleSlugs.forEach(scheduleSlug => {
            const [startMoment, endMoment] = this.getPatientScheduleStartEndMoments(
                patient,
                scheduleSlug,
                pathwayJourney
            )
            if (startMoment && endMoment) {
                if (inDate.isSameOrAfter(endMoment)) {
                    const daysBefore = inDate.clone().diff(endMoment, 'days')
                    if (daysBefore < minDaysBefore) {
                        minDaysBefore = daysBefore
                        minScheduleSlug = scheduleSlug
                    }
                }
            }
        })

        return minScheduleSlug
    }

    /**
     * From a moment and a list of schedule slugs (assumed not to overlap), find the single schedule the moment is
     * within, or undefined.
     * NOTE: The 'patient' input can be a Moment object. In this case, we assume all scheduleSlugs are relative to this moment.
     * If pathwayJourney is specified, we search for PJMs only on this PJ.
     */
    getPatientScheduleActiveAtMoment(patient, scheduleSlugs, inMoment, pathwayJourney) {
        for (const scheduleSlug of scheduleSlugs) {
            const result = this.isPatientScheduleActiveAtMoment(patient, scheduleSlug, inMoment, pathwayJourney)
            if (result) {
                return scheduleSlug
            }
        }

        return 'post-reg'
    }

    /**
     * Get all patient milestones:
     * - matching a schedule milestone slug
     * - any Schedule.qualifier applied
     * - any Schedule.filterMilestoneSlug applied
     */
    getPatientScheduleMilestones(patient, scheduleSlug) {
        const schedule = Schedule.get(scheduleSlug)
        if (schedule != undefined) {
            // Check filter
            if (schedule.filterMilestoneSlug) {
                const filterMilestoneDate = patient.getMilestoneOfSlugDate(schedule.filterMilestoneSlug)
                if (filterMilestoneDate != undefined) {
                    // Filter date exists
                    return []
                }
            }
            const nowMoment = moment()
            let milestones = patient.getMilestonesOfSlug(schedule.milestone)
            if (milestones.length > 0) {
                // Apply qualifier
                milestones = milestones.sort((a, b) => (a.moment || nowMoment).diff(b.moment || nowMoment))
                if (schedule.qualifier == Schedule.Qualifier.earliest) {
                    milestones = [milestones[0]]
                } else if (schedule.qualifier == Schedule.Qualifier.latest) {
                    milestones = [milestones[milestones.length - 1]]
                }
            }

            return milestones
        }

        return []
    }

    /**
     * Get the ReferralMilestone (should be exactly one) for the specified patient and user.
     */
    getPatientReferralMilestoneForUser(patient, user) {
        const milestones = patient.getMilestonesOfType(Milestone.Type.referral)
        const milestonesUser = milestones.filter(milestone => milestone.referredToId == user.personaId)
        if (milestonesUser.length != 1) {
            Logging.error(
                `There are ${milestonesUser.length} ReferralMilestones for patient ${patient.personaId} referred to user ${user.personaId}`
            )
        } else {
            return milestonesUser[0]
        }
    }

    /**
     * Get patient journey activities of content type.
     */
    getPatientJourneyActivitiesOfContentType(patient, contentType) {
        const journeySlug = store.state.data.selectedPatientJourney?.journeySlug || patient.journeySlug
        const journey = store.state.resources.journeys[journeySlug]
        if (!journey) {
            Logging.error(
                `Could not resolve journey: ${patient.firstJourney.journeySlug}. teamId: ${patient.firstJourney.teamId}`
            )

            return []
        }
        const activities = journey.activities.filter(activity => {
            const content = store.state.content.content[activity.contentSlug]

            return content && content.type == contentType
        })

        return activities
    }

    /**
     * Append text to patient notes.
     */
    // eslint-disable-next-line no-empty-function
    onAppendTextSuccess() {}
    onAppendTextError() {
        NotifyService.error('Failed to update patient notes.')
    }
    appendTextToPatientNotes(patient, patientJourney, text) {
        if (patientJourney.keyValues.notes) {
            patientJourney.keyValues.notes += '\n\n'
        } else {
            patientJourney.keyValues.notes = ''
        }
        patientJourney.keyValues.notes += text

        // Make the request
        const url = Request.Stem.patientJourney
            .replace('{patientId}', patient.personaId)
            .replace('{patientJourneyId}', patientJourney.patientJourneyId)

        return Request.patch(url, { keyValues: patientJourney.keyValues }).then(
            this.onAppendTextSuccess,
            this.onAppendTextError
        )
    }

    /**
     * Get the list of potential referees for the patient.
     * This is all team members in the store, excluding:
     * - Users to whom patient is already referred
     * - Users who don't have User.Capability.canViewPatientData
     */
    getPotentialRefereesForPatient(patient) {
        const patientCurrentReferees = patient.getCurrentReferees()

        // Get all users that can lead teams
        const user = store.state.user.user
        const users = _.clone(store.state.user.users)
        users[user.personaId] = user

        const teams = Object.values(store.state.user.teams).filter(team => !team.isLegacy)
        let memberIds = teams.reduce((totalIds, team) => [team.leadId, ...team.memberIds, ...totalIds], [])

        // Restrict based on permissions, and not already referred to
        let teamMembers = _.uniq(memberIds).map(id => users[id])
        teamMembers = teamMembers.filter(user => {
            return (
                user &&
                (user.inTeam || user == store.state.user.user) &&
                user.has(User.Capability.canViewPatientData) &&
                !patientCurrentReferees.includes(user)
            )
        })
        Utils.sortUsersByLastThenFirstNames(teamMembers)

        return teamMembers
    }

    /**
     * Get the journeys summary for the patient.
     * If a single journey, this is the firstJourney abbreviation, e.g. 'TKR'.
     * If multiple journeys, we add a suffix with the number of other journeys, e.g. 'TKR (+1 other)' or 'THR (+2 others)'.
     */
    getJourneysSummary(patient) {
        const journey = store.state.resources.journeys[patient.firstJourney.journeySlug]
        if (!journey) {
            Logging.error(
                `Could not resolve journey: ${patient.firstJourney.journeySlug}. teamId: ${patient.firstJourney.teamId}`
            )

            return patient.firstJourney.journeySlug
        }
        // Used localised procedureLabel if defined, else journey procedureCode (abbreviation)
        const procedureLabel = patient.firstJourney.procedureLabel
        const firstJourneyLabel = procedureLabel
            ? Locale.getLanguageItem(Locale.getStringIdFromSlug(procedureLabel, 'procedureLabel'))
            : journey.procedureCode

        switch (patient.numPatientJourneys) {
            case 1:
                return firstJourneyLabel
            case 2:
                return Locale.getLanguageItem('patientListProceduresSummaryOther', [firstJourneyLabel])
            default:
                return Locale.getLanguageItem('patientListProceduresSummaryOthers', [
                    firstJourneyLabel,
                    patient.numPatientJourneys - 1
                ])
        }
    }
    /**
     * Get the summary for a single PJ.
     * This is the firstJourney abbreviation, e.g. 'TKR'.
     */
    getPatientJourneySummary(patientJourney) {
        const journey = store.state.resources.journeys[patientJourney.journeySlug]
        if (!journey) {
            Logging.error(`Could not resolve journey: ${patientJourney.journeySlug}. teamId: ${patientJourney.teamId}`)

            return patientJourney.journeySlug
        }

        // Used localised procedureLabel if defined, else journey procedureCode (abbreviation)
        return patientJourney.procedureLabel
            ? Locale.getLanguageItem(Locale.getStringIdFromSlug(patientJourney.procedureLabel, 'procedureLabel'))
            : journey.procedureCode
    }

    /**
     * Add a PatientJourney, to the associated Patient and the store.
     */
    addPatientJourney(patientJourney) {
        const patient = store.state.user.patients[patientJourney.patientId]
        if (!patient) {
            Logging.error(
                `PatientJourney ${patientJourney.patientJourneyId} references Patient ${patientJourney.patientId} which could not be found`
            )

            return
        }
        // Add to Patient
        patient.addPatientJourney(patientJourney)

        // Add to store
        store.commit('addPatientJourney', patientJourney)

        // Rebuild stuff that's patientJourney-dependent
        patientJourney.rebuildScheduleEvents()
        patient.rebuildClinicalMilestones()
        patient.rebuildListColumns()
    }

    /**
     * Remove a PatientJourney, from the associated Patient and the store.
     */
    removePatientJourney(patientJourney) {
        const patient = store.state.user.patients[patientJourney.patientId]
        if (!patient) {
            Logging.error(
                `PatientJourney ${patientJourney.patientJourneyId} references Patient ${patientJourney.patientId} which could not be found`
            )

            return
        }
        // Remove from Patient
        patient.removePatientJourney(patientJourney)

        // Remove from store
        store.commit('removePatientJourney', patientJourney)

        // Rebuild stuff that's patientJourney-dependent
        patient.rebuildClinicalMilestones()
        patient.rebuildListColumns()
    }

    /**
     * Get clinicians from patient referrals. Returns an array of users.
     */
    getPatientReferees(patient) {
        const milestones = patient.getMilestonesOfType(Milestone.Type.referral)
        const users = new Set()
        for (const milestone of milestones) {
            if (!milestone.endDate) {
                const referee = store.state.user.users[milestone.referredToId]
                if (referee) {
                    users.add(referee)
                } else {
                    Logging.warn(
                        `Patient ${patient.personaId} is referred to user ${milestone.referredToId} who is not within dash user list.`
                    )
                }
            }
        }

        return [...users]
    }

    /**
     * Get lead clinicians from patient pathway journeys. Returns an array of users.
     * Note that the first user in the list will be the lead of the primary journey.
     */
    getPatientLeads(patient) {
        // Add clinicians leading PJ teams
        const users = []
        patient.pathwayJourneys.forEach(patientJourney => {
            const team = store.state.user.teams?.[patientJourney.teamId]
            if (team && !team.isLegacy) {
                const teamLead = store.state.user.teamLeads[team.leadId]
                if (teamLead && !users.includes(teamLead)) {
                    users.push(teamLead)
                }
            }
        })

        return users
    }

    getValidPatientRtmPeriodReviewMilestonesByCode(patient, code) {
        return patient.getMilestonesOfSlug('rtm-period-review').filter(milestone => {
            const distinctDaysPeriodStart = Utils.getPeriodStartDateBetweenDates(milestone.date, milestone.endDate)
            const distinctDays = this.getPatientDistinctDataDaysBetweenDates(
                patient,
                distinctDaysPeriodStart,
                milestone.endDate
            )

            return (
                milestone.rtmCode == code &&
                (Config.isQaEnv ||
                    milestone.reviewerId ||
                    (!milestone.reviewerId && distinctDays.numTotalDistinctDays > 15))
            )
        })
    }

    getPatientRtmPeriodReviewMilestonesByCode(patient, code) {
        return patient.getMilestonesOfSlug('rtm-period-review').filter(milestone => milestone.rtmCode == code)
    }

    getPatientRecentValidRtmPeriodReviewMilestone(patient) {
        const { FeatureFlag, getFeatureFlag } = useFeatureFlags()

        const rtm989877MilestoneCode = getFeatureFlag(FeatureFlag.rtmPeriodRefactor)
            ? RtmPeriodReviewMilestone.Code.rtm_98977
            : RtmPeriodReviewMilestone.Code.rtm_98980

        const milestones = this.getValidPatientRtmPeriodReviewMilestonesByCode(patient, rtm989877MilestoneCode)
        const sortedMilestones = _.orderBy(milestones, ['moment'], ['desc'])

        if (sortedMilestones.length > 0) {
            return sortedMilestones[0]
        }
    }

    getPatientRecentRtmPeriodReviewMilestone(patient) {
        const { FeatureFlag, getFeatureFlag } = useFeatureFlags()

        const rtm989877MilestoneCode = getFeatureFlag(FeatureFlag.rtmPeriodRefactor)
            ? RtmPeriodReviewMilestone.Code.rtm_98977
            : RtmPeriodReviewMilestone.Code.rtm_98980

        const milestones = this.getPatientRtmPeriodReviewMilestonesByCode(patient, rtm989877MilestoneCode)
        const sortedMilestones = _.orderBy(milestones, ['moment'], ['desc'])

        if (sortedMilestones.length > 0) {
            return sortedMilestones[0]
        }
    }

    hasReviewedRtmPeriodInCurrentMonth(patient) {
        const { FeatureFlag, getFeatureFlag } = useFeatureFlags()

        const rtm989877MilestoneCode = getFeatureFlag(FeatureFlag.rtmPeriodRefactor)
            ? RtmPeriodReviewMilestone.Code.rtm_98977
            : RtmPeriodReviewMilestone.Code.rtm_98980

        const milestoneIndex = patient
            .getMilestonesOfSlug('rtm-period-review')
            .findIndex(
                milestone =>
                    milestone.rtmCode == rtm989877MilestoneCode &&
                    milestone.reviewedDate &&
                    moment().isSame(moment(milestone.reviewedDate), 'month')
            )

        return milestoneIndex != -1
    }

    /**
     * For a given patient and date range, return an object detailing the number of distinct days of data the patient
     * has within this date range, for each of the following data types:
     * - pain survey results
     * - clinical survey results
     * - exercise results
     * - activity data (daily steps)
     */
    getPatientDistinctDataDaysBetweenDates(patient, startDate, endDate) {
        let exerciseDistinctDays
        let stepsDistinctDays
        let painResultDistinctDays
        let clinicalSurveyDistinctDays
        let dataDays = []

        if (patient.surveyResults) {
            // Pain
            const painResults = patient.surveyResults.filter(result => {
                const survey = store.state.content.surveys[result.surveySlug]
                if (!survey) {
                    Logging.error(`Could not find content: ${result.surveySlug}`)

                    return false
                }
                const resultEndDate = result.getEndDate()

                return (
                    survey.isPainSurvey &&
                    resultEndDate >= startDate &&
                    resultEndDate <= endDate &&
                    result.patientJourneyId == patient.firstJourney.patientJourneyId
                )
            })

            painResults.forEach(result => dataDays.push(result.getEndDate()))

            painResultDistinctDays = _.uniqBy(
                painResults.map(result => ({ resultEndDate: result.getEndDate() })),
                'resultEndDate'
            ).length

            // Other clinical surveys
            const clinicalSurveyResults = patient.surveyResults.filter(result => {
                const survey = store.state.content.surveys[result.surveySlug]
                if (!survey) {
                    Logging.error(`Could not find content: ${result.surveySlug}`)

                    return false
                }
                const resultEndDate = result.getEndDate()

                return (
                    survey.isClinicalSurvey &&
                    resultEndDate >= startDate &&
                    resultEndDate <= endDate &&
                    result.patientJourneyId == patient.firstJourney.patientJourneyId
                )
            })

            clinicalSurveyResults.forEach(result => dataDays.push(result.getEndDate()))

            clinicalSurveyDistinctDays = _.uniqBy(
                clinicalSurveyResults.map(result => ({
                    resultEndDate: result.getEndDate()
                })),
                'resultEndDate'
            ).length
        }

        // Exercises
        if (patient.exerciseResults) {
            let exerciseResults = patient.exerciseResults.filter(result => {
                return result.exerciseDate >= startDate && result.exerciseDate <= endDate && !!result.value
            })

            exerciseResults.forEach(result => dataDays.push(result.exerciseDate))

            exerciseDistinctDays = _.uniqBy(
                exerciseResults.map(result => ({ exerciseDate: result.exerciseDate })),
                'exerciseDate'
            ).length
        }

        // Activity data (steps)
        if (patient.activityDataDaily) {
            let stepsResults = patient.activityDataDaily.filter(result => {
                return result.period >= startDate && result.period <= endDate
            })

            stepsResults.forEach(result => dataDays.push(result.period))

            stepsDistinctDays = _.uniqBy(
                stepsResults.map(result => ({ stepsDate: result.period })),
                'stepsDate'
            ).length
        }

        const totalDistinctDays = _.uniq(dataDays).length

        return {
            numPainScoresDays: painResultDistinctDays,
            numDailyStepsDays: stepsDistinctDays,
            numExerciseDays: exerciseDistinctDays,
            numClinicalSurveysDays: clinicalSurveyDistinctDays,
            numTotalDistinctDays: totalDistinctDays
        }
    }

    getPatientRtmScheduledReviewMilestone(patientJourney, scheduleSlug) {
        const rtmScheduledReviewMilestones = patientJourney.getMilestonesOfType(Milestone.Type.scheduledReview) || []

        return rtmScheduledReviewMilestones.find(milestone => milestone.scheduleSlug == scheduleSlug)
    }

    getPatientScheduledReviewMilestone(patientJourney, milestoneSlug, scheduleSlug) {
        return patientJourney
            .getMilestonesOfSlug(milestoneSlug)
            .find(milestone => milestone.scheduleSlug == scheduleSlug)
    }

    getUnregisteredPatientRtmScheduledReviewMilestone(patientJourney, scheduleSlug) {
        return patientJourney
            .getMilestonesOfSlug('rtm-unregistered-review')
            .find(milestone => milestone.scheduleSlug == scheduleSlug)
    }

    /**
     * Current care period milestone:
     *      - today is within start date and planned end date
     *        (when more than one period exists, get most recent by start date)
     *      OR
     *      - today is beyond planned end date, end date is not set and milestone has most recent start date
     */
    getPatientCurrentCarePeriodMilestone(patient) {
        const carePeriodMilestones = _.orderBy(patient.getMilestonesOfSlug('care-period'), ['date'], ['desc'])
        const nowDate = new moment().format(Utils.serialisedDateFormat)

        const milestone = carePeriodMilestones.find(
            carePeriodMilestone => carePeriodMilestone.date <= nowDate && carePeriodMilestone.plannedEndDate >= nowDate
        )

        if (milestone) {
            return milestone
        }

        const milestonesWithoutEndDate = carePeriodMilestones.filter(milestone => !milestone.endDate)

        if (milestonesWithoutEndDate.length > 0) {
            return milestonesWithoutEndDate[0]
        }
    }

    /**
     * Get the count of tasks alert for care navigation in patient page (only for first journey)
     * New event alert task - If patient has a discharge date (milestone), is not within the start
     *      and planned end date of any CarePeriodMilestone, and today is 0d-90d-post-dis
     * Discharge alert task - If patient has no discharge date (milestone), and today is post-op
     * End date not set tasks (can be multiple) - If A row's Planned end date is in the past, and End date is not set
     */
    getPatientCareNavigatorNumTasks(patient) {
        let numTasks = 0
        const dischargeDate = patient.firstJourney.getMilestoneOfSlugDate('discharge')
        const nowDate = moment().format(Utils.serialisedDateFormat)

        // New event alert task
        if (dischargeDate) {
            const scheduleEndDate = moment(dischargeDate).clone().add(90, 'days').format(Utils.serialisedDateFormat)
            const currentCarePeriodMilestone = this.getPatientCurrentCarePeriodMilestone(patient)
            if (!currentCarePeriodMilestone && nowDate >= dischargeDate && nowDate <= scheduleEndDate) {
                numTasks++
            }
        }

        // Discharge alert task
        const operationDate = patient.firstJourney.getMilestoneOfSlugDate('operation')
        if (!dischargeDate && nowDate > operationDate) {
            numTasks++
        }

        // Count each care period milestone with planned end date in the past and missing end date
        const pastMilestonesWithoutEndDate = patient.firstJourney
            .getMilestonesOfSlug('care-period')
            .filter(milestone => milestone.plannedEndDate < nowDate && !milestone.endDate)

        numTasks += pastMilestonesWithoutEndDate.length

        return numTasks
    }

    /**
     * Should a journey include post-reg Activity objects for a patient?
     * NO in the following cases (both require op date):
     * - Patient reg date is within journey pre-op window (usually 2w-0w-pre-op)
     * - Patient is currently more than 6w-post-op
     * Note this calculation does not involve "now"
     */
    shouldPatientJourneyIncludePostRegSurveys(patient, patientJourney) {
        const opDate = patientJourney.getMilestoneOfSlugDate('operation')
        const journey = store.state.resources.journeys[patientJourney.journeySlug]
        if (opDate) {
            const opMoment = new moment(opDate)
            const regDate = patient.globalJourney.getMilestoneOfSlugDate('registration')
            if (!regDate) {
                return false
            }
            const regMoment = new moment(regDate)
            const startMoment = opMoment.clone().add(journey.closePreOpSchedule.startOffset, 'days')
            const endMoment = opMoment.clone().add(journey.closePreOpSchedule.endOffset, 'days')
            // NOTE: <= endMoment, to accommodate for fact that we extend pre-op survey schedules to include op date
            if (startMoment <= regMoment && regMoment <= endMoment) {
                // Yes - no need for post-reg activities
                return false
            }
            let finalMoment = opMoment.clone().add(6, 'weeks')
            const nowMoment = new moment()
            if (nowMoment > finalMoment) {
                // We're more than 6 weeks beyond op date - no need for post-reg activities
                return false
            }
        }

        return true
    }

    // Return an array of Activity objects for a Journey, by selectively filtering out post-reg Activities depending on
    // the Patient PJMs.
    getPatientJourneyActivities(patient, patientJourney, includePostReg) {
        includePostReg = includePostReg || this.shouldPatientJourneyIncludePostRegSurveys(patient, patientJourney)
        const journey = store.state.resources.journeys[patientJourney.journeySlug]
        let activities = []
        if (journey) {
            activities = includePostReg
                ? journey?.activities
                : journey?.activities.filter(act => !act.isPostRegClinicalSurvey)
        }

        // ActivityList activities
        const activityListMilestones = ActivityListService.getActivityListMilestones(patientJourney)
        const activitySlugs = ActivityListService.getActivityMilestoneActivityListSlugs(activityListMilestones)

        let activityListActivities = []
        if (activitySlugs.length > 0) {
            activityListActivities = activitySlugs.flatMap(slug => ActivityListService.getActivityBySlug(slug))
        }

        return [...activities, ...activityListActivities]
    }
    getPatientJourneyActivitiesMap(patient, patientJourney) {
        const activities = this.getPatientJourneyActivities(patient, patientJourney)
        const activitiesMap = {}
        activities.forEach(activity => (activitiesMap[activity.slug] = activity))

        return activitiesMap
    }

    getPatientRtmWindowEndMoment(patient, patientJourney, scheduleSlug) {
        const scheduleMoments = this.getPatientScheduleStartEndMoments(patient, scheduleSlug, patientJourney)
        const endMoment = scheduleMoments[1]

        if (endMoment) {
            return endMoment.add(patientJourney.rtmEndScheduleOffsetDays, 'days')
        }
    }

    getPatientRtmDischargeMoment(patient, patientJourney, scheduleSlug) {
        const endMoment = this.getPatientRtmWindowEndMoment(patient, patientJourney, scheduleSlug)

        if (endMoment) {
            const today = new moment()

            return today.isBefore(endMoment) ? endMoment : today
        }
    }

    calculateRtmEndScheduleOffsetDaysOnDischarge(patient, patientJourney, scheduleSlug) {
        const dischargeMoment = this.getPatientRtmDischargeMoment(patient, patientJourney, scheduleSlug)

        const scheduleMoments = this.getPatientScheduleStartEndMoments(patient, scheduleSlug, patientJourney)
        const endMoment = scheduleMoments[1]

        return dischargeMoment.diff(endMoment, 'days')
    }

    /**
     * If today is before planned RTM end date - extend by 30 days from RTM end date
     * If today is after planned RTM end date - extend by 30 days from today
     */
    calculateRtmEndScheduleOffsetDays(patient, patientJourney, scheduleSlug) {
        const rtmWindowEndMoment = this.getPatientRtmWindowEndMoment(patient, patientJourney, scheduleSlug)
        const todayMoment = new moment()

        let offset = patientJourney.rtmEndScheduleOffsetDays + 30

        if (rtmWindowEndMoment.isBefore(todayMoment)) {
            offset += todayMoment.diff(rtmWindowEndMoment, 'days')
        }

        return offset
    }

    isPreOpEducationAppointmentRequired(patient, patientJourney, owner) {
        const config = owner.getPatientPageTabCptConfig('GrmaTabCptPreOpEducation')

        // If journey doesn't have pre-op education content
        if (!config.journeySlugs.includes(patientJourney.journeySlug)) {
            return false
        }

        // If patient is not registered
        if (!patient.isRegistered) {
            return true
        }

        // If survey is not completed or latest survey score is less than maxScore
        if (config.surveys) {
            for (const survey of config.surveys) {
                const surveyResults = patient.surveyResults.filter(surveyResult => {
                    const schedule = Schedule.get(surveyResult.scheduleSlug)

                    return surveyResult.surveySlug == survey.slug && (schedule.isPreOp || schedule.isPostReg)
                })

                if (surveyResults.length > 0) {
                    const orderedResults = _.orderBy(surveyResults, ['endTime'], ['desc'])

                    if (orderedResults[0].score <= survey.maxScore) {
                        return true
                    }
                } else {
                    return true
                }
            }
        }

        // Patient has not watched all education videos
        const report = store.state.resources.reports && store.state.resources.reports.video
        if (report) {
            const patientVideos = report.dataset.find(item => item.patientId == patient.personaId)

            if (patientVideos && patientVideos.videosWatchedNumerator != patientVideos.videosWatchedDenominator) {
                return true
            }
        }

        // Revision procedure
        if (
            config.procedureLabel &&
            patientJourney.procedureLabel &&
            config.procedureLabel.includes(patientJourney.procedureLabel)
        ) {
            return true
        }

        // If patient didn't complete pre-op education survey
        const preopSurveyResult = patient.surveyResults.find(
            surveyResult => surveyResult.surveySlug == 'preop-education-surv'
        )
        if (!preopSurveyResult) {
            return true
        }

        // If the patient answers ‘Yes I have questions’ to pre-op education survey
        return preopSurveyResult.getScore('Status') == 1
    }

    /**
     * Process a patient list report response, to create flyweight Patient objects (if they don't exist, together with
     * their PJ objects) and to update the Patient.listColumns.
     */
    updatePatientJourneyListFromReport({ rows, listColumns, isGoddard = false, errors = [] }) {
        const patientJourneys = []

        const errorMap = {}
        for (const error of errors) {
            errorMap[error.columnId] = error.reason
        }

        rows.forEach(row => {
            Logging.log(
                `updatePatientJourneyListFromReport rows.forEach, patientId: ${row.patientId}, patientJourneyId: ${row.patientJourneyId}`
            )
            row.personaId = row.patientId
            row.isFlyweight = true
            let patientJourney = store.state.user.patientJourneys[row.patientJourneyId]
            let patient = store.state.user.patients[row.patientId]
            if (!patientJourney && !patient) {
                // Add flyweight Patient and PJ
                patientJourney = new PatientJourney(row)
                patient = new Patient(row)

                // Create flyweight globalJourney
                const globalJourney = new PatientJourney({
                    patientId: row.patientId,
                    patientJourneyId: row.globalPatientJourneyId,
                    isGlobal: true
                })
                if (row.invitationDate) {
                    globalJourney.addMilestone(
                        new Milestone({
                            slug: 'invitation',
                            date: row.invitationDate
                        }),
                        true
                    )
                }
                if (row.registrationDate) {
                    patient.isRegistered = true
                    globalJourney.addMilestone(
                        new Milestone({
                            slug: 'registration',
                            date: row.registrationDate
                        }),
                        true
                    )
                }
                patient.resolvePatientJourneys([patientJourney, globalJourney])
                store.commit('addPatient', patient)
            } else if (patientJourney && patient) {
                // Do NOT attempt to update them
            } else if (patient) {
                // Patient previously created with some other PJ. A change in the list has revealed a different PJ
                // for the same patient.
                patientJourney = new PatientJourney(row)
                patient.addPatientJourney(patientJourney)
            } else {
                Logging.error(`PatientJourney ${row.patientJourneyId} exists, but Patient ${row.patientId} does not.`)
            }
            patientJourney.listColumns = ListService.getListColumnsResolvedForPatientJourneyRow({
                inColumns: listColumns,
                patientJourney,
                row,
                isGoddard,
                errors: errorMap
            })
            patientJourneys.push(patientJourney)
        })

        return patientJourneys
    }

    isReferralNeedingReview(patientJourney, milestone) {
        const dischargeMilestone = patientJourney.getMilestoneOfSlug('discharge')

        return !dischargeMilestone && milestone.needsReview && MilestoneService.canReviewReferral(milestone)
    }

    getPatientJourneyExerciseLibraryRoutineActivity(patientJourney) {
        const journey = store.state.resources.journeys[patientJourney.journeySlug]

        return journey?.activities.find(activity => {
            const content = store.state.content.content[activity?.contentSlug]

            return content?.tags.includes('remote-pt-routine')
        })
    }

    getPatientJourneyTeamProvider(patientJourney) {
        const team = store.state.user.teams?.[patientJourney.teamId]
        if (!team) {
            return
        }

        return store.state.resources.providers[team.providerSlug]
    }
    setPatientReferralJourneys(patientObj) {
        store.commit('ensurePatients')
        const patient = store.state.user.patients[patientObj.personaId]

        if (patient) {
            patient.referralPatientJourneys = patientObj.referralPatientJourneys

            return
        }

        patientObj.isFlyweight = true
        store.commit('addPatient', new Patient(patientObj))
    }
}

export default new PatientService()
