import Logging from '@serv/Logging'
import moment from 'moment'

/**
 * A ClinicalMilestone object, which can be used in 2 ways:
 * 1. As an immutable configuration object, evaluated for a Journey, from an Owner config
 * 2. As a mutable per-patient ViewModel for ClinicalMilestoneRow.vue
 *
 * For each patient, (2) is evaluated from (1) in the context of the patient, using
 * ClinicalMilestoneService.getJourneyClinicalMilestonesResolvedForPatient(journey, patient)
 */
class ClinicalMilestone {
    constructor(object) {
        // Fields set from immutable config
        this.type = object.type || object.meta?.type || undefined
        this.label = object.label
        this.spec = object.spec
        this.scheduleSlug = object.scheduleSlug
        this.contentSlug = object.contentSlug

        if (typeof object.milestone == 'object') {
            Logging.error(
                "Trying to construct ClinicalMilestone with property 'milestone' as object - it should be a string slug"
            )
        }
        this.milestoneSlug = object.milestoneSlug || object.milestone

        // Fields set during per-patient resolution
        this.moment = object.moment || undefined

        if (object.dateString && moment(object.dateString).isValid()) {
            this.moment = moment(object.dateString)
        }
        this.surveyResult = undefined
        this.surveyResultPrev = undefined
        this.milestone = undefined // if row corresponds to a single PJM

        // row is clickable if this is set, and ClinicalMilestoneService._onClickPatientClinicalMilestoneOfType_...
        this.isClickable = object.isClickable || false

        // The following form the 'ViewModel' that is referenced by ClinicalMilestoneRow.vue, from left to right
        this.leftIconFilename = object.leftIconFilename || undefined
        this.leftText = object.leftText || undefined
        this.rightText = object.rightText || undefined
        this.rightIconFilename = object.rightIconFilename || undefined
        this.style = object.style || ClinicalMilestone.Style.default
        this.uuid = object.uuid || object.id || undefined

        this.isNeedingReview = object.isNeedingReview || false
        if (object.actionText) {
            this.actionText = object.actionText
            this.isNeedingReview = true
        }
    }

    /**
     * Get status icon, based on this.status
     */
    get statusIcon() {
        return ClinicalMilestone.getIconForStatus(this.status)
    }

    /**
     * Return the icon filename to display for a specified ClinicalMilestone.status, or undefined for none.
     */
    static getIconForStatus(status) {
        let icon
        switch (status) {
            case ClinicalMilestone.Status.past:
                icon = 'icon-cross.svg'
                break
            case ClinicalMilestone.Status.pending:
                icon = 'icon-clock-dashed.svg'
                break
            case ClinicalMilestone.Status.pendingCompletable:
                icon = 'icon-play.svg'
                break
            case ClinicalMilestone.Status.complete:
                icon = 'icon-tick.svg'
                break
        }

        return icon
    }

    /**
     * Based on the type and other properties, add a small offset to the moment value, so that rows are sorted as
     * desired in the milestones chart.
     */
    addTypeMomentOffset() {
        if (!this.moment) {
            return
        }
        let offset = ClinicalMilestone.typeToOffsetSecs[this.type] || 0
        if (this.type == ClinicalMilestone.Type.date) {
            if (this.milestoneSlug == 'invitation') {
                offset -= 2
            } else if (this.milestoneSlug == 'registration') {
                offset -= 1
            } else if (this.milestoneSlug == 'referral') {
                offset += 60
            }
            this.moment.add(offset, 'seconds')

            return
        }
        if (this.type == ClinicalMilestone.Type.survey && !this.surveyResult) {
            // Survey row with no result
            this.moment.add(2, 'hours')

            return
        }
        this.moment.add(offset, 'seconds')
    }
}

// All ClinicalMilestone types.
ClinicalMilestone.Type = {
    bundleEndDate: 'bundleEndDate',
    carePeriod: 'carePeriod',
    config: 'config', // special type for configuring milestones chart
    date: 'date',
    rtmInsufficientStepsReview: 'rtmInsufficientStepsReview',
    rtmPeriodStart: 'rtmPeriodStart',
    rtmPeriodEnd: 'rtmPeriodEnd',
    rtmPeriodInsufficient: 'rtmPeriodInsufficient',
    rtmPeriodReview: 'rtmPeriodReview',
    rtmReminder: 'rtmReminder',
    rtmStepsReview: 'rtmStepsReview',
    rtmUnregisteredReview: 'rtmUnregisteredReview',
    survey: 'survey',
    surveyCatchAll: 'surveyCatchAll' // internal type, not set on a visible row, creates rows of type survey
}

ClinicalMilestone.typeToOffsetSecs = {
    [ClinicalMilestone.Type.bundleEndDate]: 10,
    [ClinicalMilestone.Type.carePeriod]: 10,
    [ClinicalMilestone.Type.date]: 9,
    [ClinicalMilestone.Type.rtmPeriodEnd]: 10,
    [ClinicalMilestone.Type.rtmPeriodInsufficient]: 10,
    [ClinicalMilestone.Type.rtmPeriodReview]: 10,
    [ClinicalMilestone.Type.survey]: 10
}

// All ClinicalMilestone statuses.
ClinicalMilestone.Status = {
    complete: 'complete', // Milestone has been completed
    future: 'future', // Incomplete, and schedule window not started
    past: 'past', // Incomplete, and schedule window expired
    pending: 'pending', // Incomplete, and schedule window active
    pendingCompletable: 'pendingCompletable', // Incomplete, schedule window active and completable within the dash
    pendingClinicianCompletable: 'pendingClinicianCompletable', // Incomplete, schedule window active and completable by clinician
    unknown: 'unknown' // Used when statuses across a set of ClinicalMilestones are inconsistent
}

// All ClinicalMilestone icons.
ClinicalMilestone.Icon = {
    unknown: undefined, // blank
    // Status
    complete: 'icon-tick.svg',
    future: undefined, // blank
    past: 'icon-cross.svg',
    partial: 'icon-pending-circle.svg',
    pending: 'icon-clock-dashed.svg',
    pendingCompletable: 'icon-play.svg',
    warning: 'icon-warning.svg',
    // Left
    hospital: 'ic_hospital.svg',
    onboarding: 'icon-settings-accessibility.svg',
    reminder: 'ic_reminder.svg',
    survey: 'ic_compose.svg',
    training: 'ic_training.svg'
}

// All ClinicalMilestone styles.
ClinicalMilestone.Style = {
    default: 'default',
    warning: 'warning' // amber
}

export default ClinicalMilestone
